import { LearningFeeRequest, LearningType } from "@tsin-core/entities";
import { LearningFeeSubmitRequest, LearningListRequest, LearningSubmitRequest, LoadingLearningState } from "@tsin-core/models/learning.model";

export class AddLearningProgram {
    static readonly type = '[LearningProgram] Add';
    constructor(public payload: Omit<LearningSubmitRequest, 'id'>) { }
}

export class UpdateLearningProgram {
    static readonly type = '[LearningProgram] Update';
    constructor(public payload: LearningSubmitRequest) { }
}

export class UpdateLearningProgramStatus {
    static readonly type = '[LearningProgram] Update Status';
    constructor(public payload: any) { }
}

export class DeleteLearningProgram {
    static readonly type = '[LearningProgram] Delete';
    constructor(public id: string) { }
}

export class GetLearningPrograms {
    static readonly type = '[LearningProgram] Get';
    constructor(public payload: LearningListRequest) { }
}

export class GetLearningProgram {
    static readonly type = '[LearningProgram] Get One';
    constructor(public id: string) { }
}

export class AddLearningFees {
    static readonly type = '[LearningProgram] Add Fees';
    constructor(public payload: Omit<LearningFeeSubmitRequest, 'id'>) { }
}

export class DeleteLearningFee {
    static readonly type = '[LearningProgram] Delete Fee';
    constructor(public payload: string) { }
}

export class UpdateLearningFees {
    static readonly type = '[LearningProgram] Update Fees';
    constructor(public payload: LearningFeeSubmitRequest) { }
}

export class GetLearningFees {
    static readonly type = '[LearningFees] Get';
    constructor(public payload: LearningType) { }
}

export class GetLearningFee {
    static readonly type = '[LearningFees] Get One';
    constructor(public payload: LearningFeeRequest) { }
}

export class GetLearningFeeById {
    static readonly type = '[LearningSingleFee] Get One By Id';
    constructor(public payload: string) { }
}

export class SetLearningLoading {
    static readonly type = '[LearningProgram] Set Loading';
    constructor(public loading: LoadingLearningState) { }
}

export class SetLearningError {
    static readonly type = '[LearningProgram] Set Error';
    constructor(public error: string | null) { }
}
