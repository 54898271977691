import { LoadingInvoiceState } from "@tsin-core/models/invoice.model";

export class GetInvoice {
    static readonly type = '[Invoice] GET';
    constructor(public payload: string) { }
}

export class SetInvoiceLoading {
    static readonly type = '[Invoice] Set Loading';
    constructor(public loading: LoadingInvoiceState) { }
}

export class SetInvoiceError {
    static readonly type = '[Invoice] Set Error';
    constructor(public error: string | null) { }
}
// export class UpdateInvoice {
//     static readonly type = '[Invoice] Update';
//     constructor(public payload: FacilitatorRequest) { }
// }
