import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@tsin-env/environment';

const GET_INVOICE_URL = `${environment.examOldBaseUrl}/payments/invoice`;

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  http: HttpClient = inject(HttpClient);

  getInvoice(invoiceId: string) {
    return this.http.get(`${GET_INVOICE_URL}/${invoiceId}`)
  }
}
