import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetInvoice, SetInvoiceError, SetInvoiceLoading } from '@tsin-core/actions/invoice.action';
import {
    InvoiceModel,
    InvoiceStateModel,
    LoadingInvoiceState,
} from '@tsin-core/models/invoice.model';
import { InvoiceService } from '@tsin-core/services/http/invoice.service';
import { UserService } from '@tsin-core/services/http/user.service';
import { catchError, Observable, of, tap } from 'rxjs';

@State<InvoiceStateModel>({
    name: 'invoiceState',
    defaults: {
        loading: LoadingInvoiceState.notLoading,
        invoice: undefined,
        error: null,
    },
})

@Injectable()
export class InvoiceState {
    invoiceService: InvoiceService = inject(InvoiceService);
    //s
    @Selector()
    static Invoices(state: InvoiceStateModel) {
        return state.invoice;
    }

    @Selector()
    static getLoading(state: InvoiceStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: InvoiceStateModel) {
        return state.error;
    }

    @Action(GetInvoice)
    getInvoice(
        ctx: StateContext<InvoiceStateModel>,
        action: GetInvoice
    ) {
        ctx.patchState({ loading: LoadingInvoiceState.loadingList });
        return this.invoiceService.getInvoice(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                ctx.patchState({
                    invoice: result.data,
                    loading: LoadingInvoiceState.notLoading
                });
            }),
            catchError((error) => {
                ctx.patchState({
                    loading: LoadingInvoiceState.notLoading,
                    error: error.message,
                });
                return of(error);
            })
        );
    }

    @Action(SetInvoiceLoading)
    setLoading(ctx: StateContext<InvoiceStateModel>, action: SetInvoiceLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(SetInvoiceError)
    setError(ctx: StateContext<InvoiceStateModel>, action: SetInvoiceError) {
        ctx.patchState({ error: action.error });
    }

}
