import { Injectable, inject } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AuthStateModel, OTP_MEANS, UserModel } from "@tsin-core/models/auth.model";
import { AuthenticateAction, AuthenticateUserAction, GetSingleUserAction, LogoutAction, SetError } from "@tsin-core/actions/auth.action";
import { AuthService } from "@tsin-core/services/http/auth.service";
import { catchError, tap } from "rxjs/operators";
import { of } from "rxjs";

const noAuth = {
    authToken: null,
    token: null,
    id: null,
    email: null,
    isOtpEnabled: false,
    otpMeans: OTP_MEANS.NONE,
    isStaff: false,
    user: null,
};

@State<AuthStateModel>({
    name: 'authState',
    defaults: {
        loading: true,
        auth: noAuth,
        error: null,
    }
})

@Injectable()
export class AuthState {

    authService: AuthService = inject(AuthService);

    @Selector()
    static getToken(state: AuthStateModel): string | null {
        return state.auth.token;
    }

    @Selector()
    static getCurrentUser(state: AuthStateModel): UserModel | null {
        return state.auth.user;
    }

    @Selector()
    static getIsAuthenticated(state: AuthStateModel): boolean {
        return !!state.auth.authToken;
    }

    @Selector()
    static isLoading(state: AuthStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: AuthStateModel) {
        return state.error;
    }

    @Action(AuthenticateAction)
    validateLogin(ctx: StateContext<AuthStateModel>, action: AuthenticateAction) {
        ctx.patchState({ loading: true });
        console.log('Validate Login Started');
        return this.authService.validate(action.payload).pipe(
            tap((result: any) => {
                console.log('Validate Login Success', result);
                ctx.patchState({
                    auth: result,
                    loading: false
                });
            }),
            catchError((error) => {
                ctx.patchState({ loading: false, error: error.message });
                return of(error);
            })
        );
    }


    @Action(AuthenticateUserAction)
    validateLoginByUserId(ctx: StateContext<AuthStateModel>, action: AuthenticateUserAction) {
        ctx.patchState({ loading: true });
        console.log('Validate Login By UserID Started');
        return this.authService.validateUserId(action.payload).pipe(
            tap((result: any) => {
                console.log('Validate Login Success', result);
                const auth = {
                    authToken: null,
                    token: null,
                    id: null,
                    email: result?.email,
                    isOtpEnabled: false,
                    otpMeans: OTP_MEANS.NONE,
                    isStaff: false,
                    user: result,
                };

                ctx.patchState({
                    auth: auth,
                    loading: false
                });
            }),
            catchError((error) => {
                ctx.patchState({ loading: false, error: error.message });
                return of(error);
            })
        );
    }

    @Action(GetSingleUserAction)
    getSingleUser(ctx: StateContext<AuthStateModel>, action: GetSingleUserAction) {
        ctx.patchState({ loading: true });
        console.log('GET User By UserID Started');
        return this.authService.validateUserId(action.payload).pipe(
            tap((result: any) => { 
                
                const auth = {
                    authToken: null,
                    token: null,
                    id: result.authId,
                    email: result?.email,
                    isOtpEnabled: false,
                    otpMeans: OTP_MEANS.NONE,
                    isStaff: false,
                    user: result,
                };

                ctx.patchState({
                    auth: auth,
                    loading: false
                });
            }),
            catchError((error) => {
                ctx.patchState({ loading: false, error: error.message });
                return of(error);
            })
        );
    }

    @Action(LogoutAction)
    logOut(ctx: StateContext<AuthStateModel>, action: LogoutAction) {
        ctx.patchState({ loading: true });
        console.log('Logout Started');
        ctx.patchState({
            auth: undefined,
            loading: false
        });
    }




}