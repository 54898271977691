import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthRequestModel } from '@tsin-core/models/auth.model';
import { environment } from '@tsin-env/environment';

const AUTH_VALIDATE_USER = `${environment.userBaseUrl}/auth/authenticate-profiile`;
const AUTH_VALIDATE_USER_ID = `${environment.userBaseUrl}/user/single`;
const AUTH_LOGOUT = `${environment.userBaseUrl}/auth/authenticate-profiile`;

@Injectable({
    providedIn: 'root'
})

// The authentication service
export class AuthService {

    // Inject the http client into auth service
    http: HttpClient = inject(HttpClient);

    // Method for endpoint to validate the user and return a token
    validate(credentials: AuthRequestModel) {
        console.log('CREDENTIALS', credentials)
        return this.http.post(AUTH_VALIDATE_USER, credentials)
    }

    validateUserId( authReq: Omit<AuthRequestModel, "authId"> ) {
        return this.http.get(`${AUTH_VALIDATE_USER_ID}/${authReq.userId}`);
    }

    // Method for endpoint to logout a user from the server
    logout() {
        return this.http.delete(AUTH_LOGOUT)
    }
}