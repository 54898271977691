import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router'; 
import { MainHeaderComponent } from './shared/components/main-header/main-header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MainHeaderComponent, FooterComponent, QuillModule, FormsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'Touchstone Learning Service';
}