import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LearningType } from '@tsin-core/entities';
import {
  FacilitatorModel,
  FacilitatorRequest,
} from '@tsin-core/models/facilitator.model';
import { environment } from '@tsin-env/environment';
import { map, shareReplay } from 'rxjs';

const USER_URL = `${environment.userBaseUrl}`;
const GUESTS_API_URL = `${environment.userBaseUrl}/guest`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // inject the http client into the user service
  http: HttpClient = inject(HttpClient);

  // Method for getting the list of facilitators
  getFacilitatorUsers(id: string) {
    return this.http.get(USER_URL + `/user/role/${id}`);
  }

  // Method for creating a new facilitator
  createFacilitatorUser(payload: FacilitatorRequest) {
    return this.http.post(USER_URL + '/user/create-user', payload);
  }

  // Method for updating an existing facilitator
  updateFacilitatorUser(payload: FacilitatorRequest) {
    return this.http.put(USER_URL + '/user/single', payload);
  }

  // Method for deleting an existing facilitator from the server
  deleteFacilitatorUser(payload: any) {
    return this.http.delete(USER_URL + `/user/delete-account/${payload}`);
  }

  public getLearningGuests(learningType?: LearningType) {
    const params: { type: string, programType: LearningType } | { type: string } = learningType ? { type: 'learning', programType: learningType } : { type: 'learning' };

    return this.http.get<{ guest: Array<any> }>(GUESTS_API_URL, { params: params })
      .pipe(
        map(res => res.guest),
        map((allGuest) => allGuest.map(guest => ({ _id: guest._id, user: guest }))), shareReplay(1),
      )
  }

}
