import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LearningFeeRequest, LearningType } from '@tsin-core/entities';
import { LearningFeeSubmitRequest, LearningListRequest, LearningModel, LearningSubmitRequest } from '@tsin-core/models/learning.model';
import { environment } from '@tsin-env/environment';
import { Observable } from 'rxjs';

const LEARNING_API_URL = `${environment.learningBaseUrl}/learning`;
const LEARNING_CREATE_API_URL = `${environment.learningBaseUrl}/learning/create`;
const LEARNING_FEES_API_URL = `${environment.learningBaseUrl}/fees`
const LEARNING_FEES_API_SINGLE_URL = `${environment.learningBaseUrl}/fees/single`


@Injectable({
  providedIn: 'root'
})

export class LearningService {

  http: HttpClient = inject(HttpClient);

  getLearning(payload: LearningListRequest): Observable<LearningModel[]> {
    return this.http.get<LearningModel[]>(LEARNING_API_URL, { params: { time: payload.time, type: payload.type }, })
  }

  public getLearningById(learningID: string): Observable<{ data: LearningModel }> {
    return this.http.get<{ data: LearningModel }>(`${LEARNING_API_URL}/id/${learningID}`);
  }

  addLearning(payload: LearningSubmitRequest): Observable<LearningModel> {
    return this.http.post<LearningModel>(LEARNING_CREATE_API_URL, payload)
  }

  updateLearning(payload: LearningSubmitRequest): Observable<LearningModel> {
    return this.http.put<LearningModel>(LEARNING_API_URL, payload)
  }

  setLearningFees(payload: Omit<LearningFeeSubmitRequest, 'id'>): Observable<LearningModel> {
    return this.http.post<any>(LEARNING_FEES_API_URL, payload)
  }

  updateLearningFees(payload: LearningFeeSubmitRequest): Observable<LearningModel> {
    return this.http.put<any>(LEARNING_FEES_API_URL, payload)
  }

  deleteLearningFee(id: string): Observable<LearningModel> {
    return this.http.delete<LearningModel>(`${LEARNING_FEES_API_SINGLE_URL}/${id}`);
  }

  getLearningFees(payload: LearningType) {
    return this.http.get(LEARNING_FEES_API_URL, { params: { type: payload }, })
  }

  getLearningFee(payload: LearningFeeRequest) {
    return this.http.get(LEARNING_FEES_API_URL, { params: { type: payload.type, mode: payload.mode, year: payload.year }, })
  }

  getLearningFeeById(payload: string) {
    return this.http.get(`${LEARNING_FEES_API_URL}/single/${payload}`)
  }

  updateLearningStatus(data: { id: string, activate: boolean }) {
    return this.http.patch(`${LEARNING_API_URL}`, data);
  }

  deleteLearning(payload: string): Observable<LearningModel> {
    return this.http.delete<LearningModel>(`${LEARNING_API_URL}/id/${payload}`);
  }

}
