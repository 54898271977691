import { LearningType } from "@tsin-core/entities";

export interface LearningListRequest {
    time: string;
    type: string;
}

export interface LearningStateModel {
    learningPrograms: LearningModel[];
    learningFees: LearningFeeModel[];
    selectedLearningProgram: LearningModel | null,
    selectedLearningFee: LearningFeeModel | null,
    loading: LoadingLearningState;
    error: any | null;
}

export interface LearningModel {
    id: string,
    learningNo: string,
    startDate: Date,
    endDate: Date,
    capacity: 100,
    isActive: boolean,
    name: string,
    type: LearningType,
    _count: {
        applications: number
    },
    totalRegistrations: number, 
    year: number, 
    mode: string, 
    registrationFee: string,
    seatsAvailable: string,
    stage: string,
    stageShort: string
}

export interface LearningSubmitRequest {
    type: string,
    startDate: Date,
    endDate: Date,
    capacity: number,
    isActive: boolean,
    status?: string,
    mode?: string,
    stage?: string,
    stageShort?: string,
    year?: any,
    name: string,
    id?: string
}

export interface LearningFeeSubmitRequest {
    type: LearningType,
    amount: number,
    year: number,
    name: string,
    mode: string,
    id?: string,
}


export interface LearningFeeModel {
    type: LearningType,
    amount: number,
    year: number,
    name: string,
    mode: string,
    id: string,
}

export interface FeeTypeListModel {
    id: string;
    type: LearningType;
    learningType: string;
    registrationFee: number;
    administrativeFee: number;
}

export enum LoadingLearningState {
    loadingList = "loadLearningList",
    loadingAddUpdate = "loadLearningForm",
    loadingDelete = "loadLearningDelete",
    notLoading = "notLoadingLearning",
}