import { AuthModel, AuthRequestModel } from "@tsin-core/models/auth.model";

const ACTION_SCOPE = '[Auth]';

export class AuthenticateAction {
  static readonly type = `${ACTION_SCOPE} Login`;
  constructor(public payload: AuthRequestModel) { }
}

export class SaveAuthToken {
  static readonly type = `${ACTION_SCOPE} Save Auth Token`;
  constructor(public payload: { authToken: string, userId: string, service: string }) { }
}

export class AuthenticateUserAction {
  static readonly type = `${ACTION_SCOPE} Login BY USER`;
  constructor(public payload: Omit<AuthRequestModel, "authId">) { }
}

export class GetSingleUserAction {
  static readonly type = `${ACTION_SCOPE} GET USER BY ID`;
  constructor(public payload: Omit<AuthRequestModel, "authId">) { }
}

export class LogoutAction {
  static readonly type = `${ACTION_SCOPE} Logout`;
}


export class SetLoading {
  static readonly type = `${ACTION_SCOPE} Set Loading`;
  constructor(public loading: boolean) { }
}

export class SetError {
  static readonly type = `${ACTION_SCOPE} Set Error`;
  constructor(public error: string | null) { }
}
