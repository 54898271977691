export interface AuthRequestModel {
    userId: string;
    authId: string;
}
 

export interface AuthStateModel {
    auth: AuthModel;
    loading: boolean;
    error: any | null;
}

export enum OTP_MEANS {
    APP = 'App',
    EMAIL = 'Email',
    NONE = 'App',
}

export interface AuthModel {
    authToken: string | null;
    token: string | null;
    id: string | null,
    email: string | null,
    isOtpEnabled: boolean,
    otpMeans: "App" | "Email" | "None",
    isStaff: boolean,
    user: UserModel | null,
}

export interface UserModel {
    office: string,
    _id: string,
    email: string,
    phone: string,
    avatar: string,
    firstName: string,
    lastName: string,
    gender: string,
    dateOfBirth: Date,
    legalStatus: string,
    countryOfBirth: string,
    motherTongue: string,
    streetAdress: string,
    unitNumber: number,
    country: string,
    state: string,
    city: string,
    zipCode: string,
    emergencyName: string,
    emergencyRelationship: string,
    emergencyPhone: string,
    emergencyEmail: string,
    univer: string,
    degreeProgrammArea: string,
    degreeAchived: string,
    educationCountry: string,
    practiceLang: string,
    yearsOfPractice: string,
    educationLang: string,
    role: string,
    status: string,
    learnerNumber: string,
    learnerType: string,
    uploadDate: Date,
    isCanContact: boolean,
    accountStatus: string,
    accountType: string,
    authId: string,
    isStaff: boolean,
    updatedAt: Date,
    examType: string,
    id: string,
    learningId: string,
    learningProgramId: string,
    sponsored: string,
}