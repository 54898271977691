export const environment = {
    production: false,
    learningUrl: 'https://learning-dev.tsin.ca/',
    userBaseUrl: 'https://user-dev-be.tsin.ca/api',
    userMgtUrl: 'https://user-dev.tsin.ca/auth/login',
    examOldUrl: 'https://portalstage.tsin.ca/',
    paymentUrl: 'https://payment-dev.tsin.ca',
    learningBaseUrl: 'https://learning-dev-be.tsin.ca/api',
    examOldBaseUrl: 'https://portalstage-be.tsin.ca/api',
    salt: 'ZC\f?.Kp[jnhXf3-"<f3{/tZS@#~(8on',
    paymentMode: 'qa',
    paymentTimeLimitInMs: 180000,
};
