import { UserModel } from "./auth.model";

export interface UserStateModel {
    users: UserModel[];
    guests: GuestModel[];
    loading: LoadingUserState;
    error: any | null;
}

export interface GuestModel {
    _id: string | null;
    user: UserModel | null;
    id: string | null
}

 
export enum LoadingUserState {
    loadingList = "loadingList",
    loadingAddUpdate = "loadingAddUpdate",
    loadingDelete = "loadingDelete",
    notLoading = "notLoading",
}