import { assertInInjectionContext, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@tsin-core/states/auth.state';
import { environment } from '@tsin-env/environment';



const canActivateFn: CanActivateFn = () => {
  assertInInjectionContext(canActivateFn);
  const router: Router = inject(Router);
  const store: Store = inject(Store);

  const isLogin = store.selectSnapshot(AuthState.getIsAuthenticated);
  console.info(`isLogin ${isLogin}, will activate`);
  if (isLogin) {
    return true;
  }

  return router.parseUrl('/account/validate-login');
};
 
export const authGuard: CanActivateFn = (route, state) => {
  return canActivateFn(route, state);

};
