export const environment = {
  production: false,
  learningUrl: 'https://learning-dev.tsin.ca/',
  // userBaseUrl: 'https://user-dev-be.tsin.ca/api',
  userBaseUrl: 'https://zxkbbj3pcy.us-east-1.awsapprunner.com/api',
  // userMgtUrl: 'https://user-dev.tsin.ca/auth/login',
  userMgtUrl: 'https://d2xhgoo376k0s0.cloudfront.net/',
  examOldUrl: 'https://portalstage.tsin.ca/',
  paymentUrl: 'https://payment-dev.tsin.ca',
  learningBaseUrl: 'https://learning-dev-be.tsin.ca/api',
  examOldBaseUrl: 'https://portalstage-be.tsin.ca/api',
  userBaseUrlFallback: 'https://user-dev-be.tsin.ca/api',
  userBaseMgtUrlFallback: 'https://user-dev.tsin.ca/auth/login',
  salt: 'ZC\f?.Kp[jnhXf3-"<f3{/tZS@#~(8on',
  paymentMode: 'qa',
  paymentTimeLimitInMs: 180000,
};
