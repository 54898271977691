import { ApplicationModel } from "./application.model";

export interface InvoiceStateModel {
    invoice?: InvoiceModel;
    loading: LoadingInvoiceState;
    error: any | null;
}

export interface InvoiceModel {
    _id?: string;
    examType: string,
    userId: string,
    applicationId: string,
    status: string,
    amount: number,
    meta?: ExamMetaModel | ApplicationModel | null,
    createdAt: Date,
}

export interface ExamMetaModel {
    _id: string,
    id: string,
    oscedate: string,
    oscetype: string,
    mcqdate?: Date,
    mcqtype: string,
    tpaodate?: Date,
    tpaotype: string,
    shortoscedate?: Date,
    shortoscetype?: string,
    longoscedate1?: Date,
    longoscedate2?: Date,
    longoscetype: string,
    capacity: number,
    activate: boolean,
    type: string,
    registrationFee: number,
    administrativeFee: number,
    seatsAvailable: number
}

export enum LoadingInvoiceState {
    loadingList = "loadingInvoice",
    loadingAddUpdate = "loadingCreateInvoice",
    loadingDelete = "loadingDeleteInvoice",
    notLoading = "loadingInvoiceComplete",
}