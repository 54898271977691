import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetLearningGuests, GetLearningUsers, SetUserError, SetUserLoading } from '@tsin-core/actions/user.action';
import { LoadingUserState, UserStateModel } from '@tsin-core/models/user.model';
import { LearnerService } from '@tsin-core/services/http/learner.service';
import { UserService } from '@tsin-core/services/http/user.service';
import { catchError, Observable, of, tap } from 'rxjs';

@State<UserStateModel>({
    name: 'userState',
    defaults: {
        loading: LoadingUserState.loadingList,
        users: [],
        guests: [],
        error: null,
    },
})
@Injectable()
export class UserState {
    userService: UserService = inject(UserService);
    learnerService: LearnerService = inject(LearnerService);
    //
    @Selector()
    static getGuests(state: UserStateModel) {
        return state.guests;
    }

    @Selector()
    static getUsers(state: UserStateModel) {
        return state.users;
    }

    @Selector()
    static getLoading(state: UserStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: UserStateModel) {
        return state.error;
    }

    @Action(GetLearningGuests)
    getLearningGuests(
        ctx: StateContext<UserStateModel>,
        action: GetLearningGuests
    ) {
        ctx.patchState({ loading: LoadingUserState.loadingList });
        return this.userService.getLearningGuests(action.type).pipe(
            tap((guests: any) => {
                ctx.patchState({
                    guests: guests,
                    loading: LoadingUserState.notLoading,
                    error: null,
                });
            }),
            catchError((error) => {
                ctx.patchState({
                    loading: LoadingUserState.notLoading,
                    error: error.message,
                });
                return of(error);
            })
        );
    }


    @Action(GetLearningUsers)
    getLearningUsers(
        ctx: StateContext<UserStateModel>,
        action: GetLearningUsers
    ) {
        ctx.patchState({ loading: LoadingUserState.loadingList });
        return this.learnerService.exportLearnersById(action.type).pipe(
            tap((users: any) => {
                console.log(users);
                ctx.patchState({
                    users: users,
                    loading: LoadingUserState.notLoading,
                    error: null,
                });
            }),
            catchError((error) => {
                ctx.patchState({
                    loading: LoadingUserState.notLoading,
                    error: error.message,
                });
                return of(error);
            })
        );
    }

    @Action(SetUserLoading)
    setLoading(ctx: StateContext<UserStateModel>, action: SetUserLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(SetUserError)
    setError(ctx: StateContext<UserStateModel>, action: SetUserError) {
        ctx.patchState({ error: action.error });
    }
}
